.dataTable-wrapper {
  .dataTable-top {
    .dataTable-dropdown {
      label {
        display: flex;
        align-items: center;
        white-space: nowrap;
        select {
          margin-right: 10px;
        }
      }
    }
    .dataTable-search {
      .dataTable-input {
        @extend .form-control;
      }
    }
  }
  .dataTable-container {
    .dataTable-table {
      th {
        a.dataTable-sorter {
          &::before {
            bottom: -2px;
          }
          &::after {
            top: -2px;
          }
        }
      }
      th, td {
        padding: $table-cell-padding;
      }
    }
  }
  .dataTable-bottom {
    .dataTable-pagination {
      li {
        @extend .page-item;
        a {
          @extend .page-link;
          color: theme-color(primary);
        }
        &.active {
          a {
            color: #fff;
            background-color: theme-color(primary);
            border-color: theme-color(primary);
          }
        }
      }
    }
  }
}