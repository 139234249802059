// Plugin: Ngx-datatable
// github: https://github.com/swimlane/ngx-datatable

.ngx-datatable {
  &.bootstrap {
    .datatable-header {
      .datatable-header-cell {
        border-bottom-color: $border-color;
      }
    }
    .datatable-body {
      .datatable-body-row {
        border-top-color: $border-color;
        &.datatable-row-even {
          background-color: $table-accent-bg;
        }
      }
    }
    .datatable-footer {
      background: $table-accent-bg;
      color: $body-color;
      .datatable-pager {
        ul {
          li {
            a {
              color: $body-color;
            }
            &:not(.disabled) {
              &.active,
              &:hover {
                a {
                  background-color: rgba(theme-color(dark), .6);
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}