.email-head-title {
  padding: 15px;
  border-bottom: 1px solid $border-color;
  font-weight: 400;
  color: #3d405c;
  font-size: .99rem;
  .icon {
    color: #696969;
    margin-right: 12px;
    vertical-align: middle;
    line-height: 31px;
    position: relative;
    top: -1px;
    float: left;
    font-size: 1.538rem;
  }
}

.email-compose-fields {
  background-color: transparent;
  padding: 20px 15px;
}

.form-group.row {
  margin-bottom: 0;
  padding: 12px 0;
  label {
    white-space: nowrap;
  }
}

.email-compose-fields label {
  padding-top: 6px;
}

.email {
  &.editor {
    background-color: transparent;
    .editor-statusbar {
      display: none;
    }
  }
  &.action-send {
    padding: 8px 0 0;
  }
}

.btn-space {
  margin-right: 5px;
  margin-bottom: 5px;
}

.breadcrumb {
  margin: 0;
  background-color: transparent;
}

.rtl {
  .btn-space {
    margin-right: 0;
    margin-left: 5px;
  }
}